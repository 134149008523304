













import Vue from "vue";
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import Partner from "@/modules/partner/models/Partner";
import { store, TechnicalInvoiceStore } from "../services/TechnicalInvoiceStore";

interface Data {
  store: TechnicalInvoiceStore;
}

interface Methods {
  partnerSelected(partner: Partner): void;
  cancel(): void;
}

export default Vue.extend<Data, Methods, {}>({
  data() {
    return {
      store,
    };
  },
  components: {
    PartnerSearch,
  },

  methods: {
    // parameterbe megkapjuk a partnert, lásd PartnerSearch-methods-afterClose
    partnerSelected(partner) {
      this.store.tInvoicePartner = { ...partner }; // elrakjuk a storeba a kiv partnert
      this.$router.push(`${this.$route.params.codeFilter}/create`);
    },

    cancel() {
      this.$router.go(-1); // vissza 1 lap
    },
  },
});
